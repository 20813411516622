import {
  ConfigValue,
  BackgroundAnimationType,
} from '@longnecktech/splash-commons-fe';
import { Theme } from '@shared/types/theme';

function hexToRgb(hex: string) {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r}, ${g}, ${b}`;
}

function getAnimationVariables(
  bgAnimationType: BackgroundAnimationType | null,
): string {
  if (!bgAnimationType) return '';
  if (bgAnimationType === BackgroundAnimationType.SNOW) {
    return '--particle-color: #ffffff';
  }
  if (bgAnimationType === BackgroundAnimationType.SPARKLE) {
    return '--particle-color: #f4b300';
  }
  return '';
}

export function injectCSSVariablesIntoDOM(theme: Theme) {
  const styleEl = document.createElement('style');
  const animationVariables = getAnimationVariables(theme.backgroundAnimation);
  const fonts = `
      @font-face {
        font-family: PrimaryFont;
        src: url('${
          theme.primaryFontRegularAsset?.url
            ? theme.primaryFontRegularAsset.url
            : '/assets/fonts/Montserrat/static/Montserrat-Regular.ttf'
        }') format('truetype');
      }

      @font-face {
        font-family: PrimaryFont;
        src: url('${
          theme.primaryFontBoldAsset?.url
            ? theme.primaryFontBoldAsset.url
            : '/assets/fonts/Montserrat/static/Montserrat-Bold.ttf'
        }') format('truetype');
        font-weight: 700;
      }

      @font-face {
        font-family: PrimaryFont;
        src: url('${
          theme.primaryFontMediumAsset?.url
            ? theme.primaryFontMediumAsset.url
            : '/assets/fonts/Montserrat/static/Montserrat-Medium.ttf'
        }') format('truetype');
        font-weight: 500;
      }

      @font-face {
        font-family: Headlines;
        src: url('${
          theme.jackpotFontAsset?.url
            ? theme.jackpotFontAsset.url
            : '/assets/fonts/Montserrat/static/Montserrat-Regular.ttf'
        }') format('truetype');
      }
    `;

  styleEl.textContent = `:root {
      ${theme.cssVariables
        .filter((v) => v.key.includes('--') && v.value)
        .map((v: ConfigValue) => {
          const originalValue = v.value;
          let rgbValue;

          if (originalValue.startsWith('rgba')) {
            const match = originalValue.match(/\d+/g);
            if (match) {
              rgbValue = `${match[0]}, ${match[1]}, ${match[2]}`;
            }
          } else if (originalValue.startsWith('rgb')) {
            const match = originalValue.match(/\d+/g);
            if (match) {
              rgbValue = match.join(', ');
            }
          } else if (originalValue.startsWith('#')) {
            rgbValue = hexToRgb(originalValue);
          }

          return `
          ${v.key}: ${originalValue};
          ${v.key}-rgb: ${rgbValue};
        `;
        })
        .join('\n')}
      ${animationVariables}
    }
    ${fonts}
    `;
  document.head.appendChild(styleEl);
}
